.body {
	@apply text-base leading-7 max-w-6xl mx-auto;
}

.heading {
	@apply text-5xl pt-1 pb-2 font-semibold tracking-wide cursor-pointer mb-2;
}

.pageHeading {
	@apply pt-1 pb-4 text-2xl leading-7 font-bold tracking-wide;
}

.sectionHeading {
	@apply pt-1 pb-2 text-2xl font-bold tracking-wide cursor-pointer mb-2;
}

/* Apply base font sizes and styles for typography markup (h2, h2, ul, p, etc.).
   A helpful addition for whenn page content is consumed from a source managed through a wysiwyg editor. */

.body :is(h1, h2, h3, h4, h5, h6, p, ul, ol) {
	@apply mb-4;
}

.body :is(h1, h2, h3, h4, h5, h6):not(:first-child) {
	@apply mt-8;
}

.body :is(h1, h2, h3, h4, h5, h6) {
	@apply font-semibold tracking-wide;
}

.body h1 {
	@apply text-5xl;
}

.body h2 {
	@apply text-4xl;
}

.body h3 {
	@apply text-3xl;
}

.body h4 {
	@apply text-2xl;
}

.body h5 {
	@apply text-xl;
}

.body h6 {
	@apply text-lg;
}

.body ul,
.body ol {
	@apply pl-6;
}

.body ul {
	@apply list-disc;
}

.body ol {
	@apply list-decimal;
}

.body a {
	@apply underline;
}

.body a:hover {
	@apply no-underline;
}
